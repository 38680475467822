import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../utils/analytics';
import { displayGameId } from '../utils/display';
import { GREEN_THRESHOLD, YELLOW_THRESHOLD } from '../utils/misc';
import { Countdown } from './Countdown';
import { Icons } from './Icons';

function End({ state, lastGameId, language, hideCountdown, onPlayAgain }) {
  const { t } = useTranslation();
  const [sharedMsg, setSharedMsg] = useState('');

  const { guessHistory, numberOfAttempts, numberOfTips, gameId, gaveUp } =
    state;

  const isMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const isFirefox = () =>
    navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;

  const displaySharedMsg = (msg) => {
    setSharedMsg(msg);
    setTimeout(() => setSharedMsg(''), 3000);
  };

  const getChart = () => {
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    let greenSquares = 0;
    let yellowSquares = 0;
    let redSquares = 0;

    guessHistory.forEach((item) => {
      if (item[1] < GREEN_THRESHOLD) greenCount += 1;
      else if (item[1] < YELLOW_THRESHOLD) yellowCount += 1;
      else redCount += 1;
    });

    const max = Math.max(greenCount, yellowCount, redCount);
    let total = 20;

    if (max <= 25) {
      total = 5;
    } else if (max <= 50) {
      total = 10;
    } else if (max <= 100) {
      total = 15;
    }

    const totalWords = guessHistory.length;

    if (totalWords > 0) {
      greenSquares = Math.round((greenCount / totalWords) * total);
      yellowSquares = Math.round((yellowCount / totalWords) * total);
      redSquares = Math.round((redCount / totalWords) * total);

      greenSquares = greenSquares === 0 && greenCount > 0 ? 1 : greenSquares;
      yellowSquares =
        yellowSquares === 0 && yellowCount > 0 ? 1 : yellowSquares;
      redSquares = redSquares === 0 && redCount > 0 ? 1 : redSquares;

      greenSquares = Math.min(greenCount, greenSquares);
      yellowSquares = Math.min(yellowCount, yellowSquares);
      redSquares = Math.min(redCount, redSquares);
    }

    let chart = '';

    for (let i = 0; i < greenSquares; i += 1) {
      chart += '🟩';
    }
    chart += ` ${greenCount}\n`;
    for (let i = 0; i < yellowSquares; i += 1) {
      chart += '🟨';
    }
    chart += ` ${yellowCount}\n`;
    for (let i = 0; i < redSquares; i += 1) {
      chart += '🟥';
    }
    chart += ` ${redCount}`;

    return chart;
  };

  const handleShareButton = () => {
    const resultText = gaveUp ? t('end.butGaveUp') : t('end.andGotIt');
    const tipText =
      numberOfTips > 0
        ? ` ${t('end.and')} ${numberOfTips} ${t('end.tips')}`
        : '';

    let text = `${t('end.played')} contexto.me #${displayGameId(
      gameId
    )} ${resultText} ${t('end.with')} ${numberOfAttempts} ${t(
      'end.attempts'
    )}${tipText}.\n\n`;

    text += getChart();

    if (isMobile() && !isFirefox() && navigator.share) {
      navigator
        .share({ text })
        .then(() => {
          displaySharedMsg(t('end.done'));
        })
        .catch(() => {});
    } else {
      navigator.clipboard.writeText(text);
      displaySharedMsg(t('end.copied'));
    }

    track.share(language);
  };

  return (
    <div className="end-msg">
      <p className="bigger">
        {gaveUp ? (
          <span>{t('end.betterLuck')}</span>
        ) : (
          <span>{t('end.congrats')}</span>
        )}
      </p>
      <p>
        {gaveUp ? (
          <span>{t('end.youGaveUpThe')}</span>
        ) : (
          <span>{t('end.youGotThe')}</span>
        )}{' '}
        {t('end.word')} <b>#{displayGameId(gameId)}</b>
        <br />
        {t('end.in')} <b>{numberOfAttempts}</b> {t('end.attempts')}
        {numberOfTips > 0 && (
          <>
            {' '}
            {t('end.and')} <b>{numberOfTips}</b> {t('end.tips')}
          </>
        )}
        .
      </p>
      {!!gaveUp && (
        <p>
          {t('end.theWordWas')} <b>{gaveUp.toUpperCase()}</b>.
        </p>
      )}
      <div className="chart-wrapper">
        <div className="chart">{getChart()}</div>
      </div>
      <div>
        <div style={{ position: 'relative', padding: '15px 0' }}>
          <button type="button" className="button" onClick={handleShareButton}>
            <Icons.Share />
            <div style={{ minWidth: gaveUp ? 222 : 104 }}>
              {sharedMsg ||
                `${t('end.share')} ${gaveUp ? t('end.anyway') : ''}`}
            </div>
          </button>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <div className="play-again-text">{t('end.playAgain')}:</div>
        <button type="button" className="button" onClick={onPlayAgain}>
          <Icons.Calendar />
          {t('Previous games')}
        </button>
      </div>
      {!hideCountdown && gameId === lastGameId && <Countdown />}
    </div>
  );
}

export { End };
