import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const getReturnValues = (countDown) => {
  // calculate time left
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

const useCountdown = () => {
  const countDownDate = dayjs().add(1, 'day').startOf('day');
  const [countDown, setCountDown] = useState(countDownDate - dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      const newValue = countDownDate - dayjs();
      if (newValue > 0) {
        setCountDown(newValue);
      } else {
        setCountDown(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return getReturnValues(countDown);
};

export { useCountdown };
